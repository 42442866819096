<template>
	<div v-if="hasMultiplePages" class="pagination">
		<button type="button" @click="previousPage" :class="{hidden: isFirstPage}">&lt;</button>
		<span class="page-number">Page {{currentPage}}</span>
		<button type="button" @click="nextPage" :class="{hidden: isLastPage}">&gt;</button>
	</div>
</template>

<script>
	export default {
		props: {
			modelValue: Number,
			data: Array,
			maxRecords: {
				type: Number,
				default: 100
			}
		},
		emits: ['update:modelValue', 'pageChanged'],
		computed: {
			currentPage: { // Enables the use of the v-model directive with this component.
				get() {
					return this.modelValue;
				},
				set(value) {
					this.$emit('update:modelValue', value);
				}
			},
			isFirstPage() { // Used to determine whether the page currently being displayed is the first page.
				return (this.currentPage == 1);
			},
			isLastPage() { // Used to determine whether the page currently being displayed is the last page.
				return (this.data.length < this.maxRecords);
			},
			hasMultiplePages() { // Used to determine whether there are multiple pages worth of data.
				return !(this.isFirstPage && this.isLastPage);
			}
		},
		methods: {
			previousPage() { // Used to move to the next page when the next page button is clicked.
				if(!this.isFirstPage) {
					this.currentPage--;
					this.$emit('pageChanged');
				}
			},
			nextPage() { // Used to move to the previous page when the previous page button is clicked.
				if(!this.isLastPage) {
					this.currentPage++;
					this.$emit('pageChanged');
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.pagination {
		float:right;
		margin-top:10px;
	}
	
	.page-number {
		margin:0 10px;
	}
	
	button.hidden {
		visibility:hidden;
	}
</style>